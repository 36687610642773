<template>
  <div class="expand">
    <el-tree :data="tree" :props="{ children: 'sections', label: 'name' }">
      <div slot-scope="{ node, data }" style="width: 100%;display: flex">
        <div
          style="flex: auto;color: #333333"
          :style="{
            'font-size': data.sections ? '14px' : '12px',
            'font-weight': data.sections ? 500 : 400
          }"
        >
          {{ node.label }}
        </div>
        <div style="color: #568FEB;width: 150px" :style="{ 'font-size': data.sections ? '14px' : '12px' }">
          学习进度
          {{ data.sections ? Math.round(data.progress * 100) || 0 : Math.round(data.maxProgress * 100) || 0 }}%
        </div>
        <div style="color: #2B343D;width: 240px" :style="{ 'font-size': data.sections ? '14px' : '12px' }">
          {{ initTime(data) }}
        </div>
      </div>
    </el-tree>
    <Spin fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import questionRepo from '@api/question'
import filters from '../../../util/filterUtils'

export default {
  name: 'ExpandRow',
  props: {
    row: Object
  },
  data() {
    return {
      tree: [],
      spinShow: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initTime(data) {
      if (data.sections) {
        if (data.sections.length === 0) {
          return ''
        } else {
          return '上次学习时间'
        }
      } else {
        return filters.formatTime(data.updatedTime, 'yyyy-MM-dd hh:mm')
      }
    },
    initData() {
      this.spinShow = true
      questionRepo
        .getStudyDetail(this.row.courseId)
        .then(res => {
          this.tree = res.res.chapters
          this.spinShow = false
        })
        .catch(() => {
          this.spinShow = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.expand {
  background: rgba(247, 247, 247, 1);
  position: relative;
  opacity: 0.6;
  ::v-deep .el-tree {
    background: rgba(247, 247, 247, 1);
  }
}
</style>
